.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* @link https://utopia.fyi/space/calculator?c=480,32,1.2,1180,96,1.25,5,2,&s=,,s-l&g=s,l,xl,12 */
    margin-block: clamp(var(--space-32), -0.7429rem + 9.1429vw, var(--space-96));
}

.grid {
    align-self: normal;
    display: grid;
    grid-template-columns: var(--grid-columns, repeat(auto-fit, minmax(min(250px, 100%), 340px)));
    gap: var(--space-12);
    place-content: center;

    @media (--screen-gt-xlg) {
        --grid-columns: repeat(auto-fit, 338px);
    }
}
